export const NODE_ENV = {
  PRODUCTION: 'production',
};

export const CONFIG = {
  env: import.meta.env.VITE_APP_ENV,
  serverRoot: import.meta.env.VITE_SERVER_ROOT,
  filestackApiKey: import.meta.env.VITE_FILESTACK_API_KEY,
  s3Bucket: import.meta.env.VITE_S3_BUCKET,
  pusherKey: import.meta.env.VITE_PUSHER_KEY,
  miroApiKey: import.meta.env.VITE_MIRO_API_KEY,
  getStreamApiKey: import.meta.env.VITE_GET_STREAM_API_KEY,

  sentryDomain: 'a-world-for-us',
  sentryRelease: import.meta.env.VITE_SENTRY_RELEASE,
  sentryEnv: import.meta.env.VITE_SENTRY_ENV,
  sentryDsn: import.meta.env.VITE_SENTRY_DSN,

  extranetDevServerRoot: import.meta.env.VITE_EXTRANET_DEV_SERVER_ROOT,

  dokkuInstanceName: import.meta.env.VITE_DIGIFORMA_DOKKU_INSTANCE,
};
