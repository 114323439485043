const RGB_CONVERTER_REGEX = /^#?([a-fA-Z0-9]{2})([a-fA-Z0-9]{2})([a-fA-Z0-9]{2})([a-fA-Z0-9]{2})?$/;

type RGBChannels = [number, number, number];
type HSLChannels = [number, number, number];

export function computeNormalizedColorChannels(colorAsRGBString: string) {
  const matchRes = colorAsRGBString.match(RGB_CONVERTER_REGEX);
  if (matchRes) {
    return matchRes.slice(1).map(hexValue => normalizeHexValue(hexValue));
  } else {
    return [0.0, 0.0, 0.0];
  }
}

function normalizeHexValue(hexValueString: string) {
  return parseInt(hexValueString, 16) / 255.0;
}

export function computeLuminance(normalizedColorChannels: RGBChannels) {
  const [r, g, b] = normalizedColorChannels.map(c => linearizeColorChannel(c));
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

function linearizeColorChannel(colorChannel: number) {
  if (colorChannel <= 0.03928) {
    return colorChannel / 12.92;
  } else {
    return Math.pow((colorChannel + 0.055) / 1.055, 2.4);
  }
}

export function computePerceptualLightness(luminance: number) {
  // Send this function a luminance value between 0.0 and 1.0,
  // and it returns L* which is "perceptual lightness"

  if (luminance <= 216 / 24389) {
    // The CIE standard states 0.008856 but 216/24389 is the intent for 0.008856451679036
    return luminance * (24389 / 27); // The CIE standard states 903.3, but 24389/27 is the intent, making 903.296296296296296
  } else {
    return Math.pow(luminance, 1 / 3.0) * 116 - 16;
  }
}

export function computeContrastRatio(foregroundColor: RGBChannels, backgroundColor: RGBChannels) {
  const textColorRGBLuminance = computeLuminance(foregroundColor);
  const backgroundColorRGBLuminance = computeLuminance(backgroundColor);
  const l1 = Math.max(textColorRGBLuminance, backgroundColorRGBLuminance);
  const l2 = Math.min(textColorRGBLuminance, backgroundColorRGBLuminance);
  return (l1 + 0.05) / (l2 + 0.05);
}

export function computeNormalizedRGBtoHSL([r, g, b]: RGBChannels) {
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;

  let h = 0;

  if (delta === 0) {
    h = 0;
  } else if (cmax === r) {
    h = ((g - b) / delta) % 6;
  } else if (cmax === g) {
    h = (b - r) / delta + 2;
  } else {
    h = (r - g) / delta + 4;
  }

  h = Math.round(h * 60);

  if (h < 0) {
    h += 360;
  }

  const l = (cmax + cmin) / 2;
  const s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  return [h, s, l];
}

export function computeHSLtoNormalizedRGB([h, s, l]: HSLChannels) {
  const c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2;

  let r = 0,
    g = 0,
    b = 0;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }

  r = Math.min(r + m, 1);
  g = Math.min(g + m, 1);
  b = Math.min(b + m, 1);

  return [r, g, b];
}

export function lighten([h, s, l]: HSLChannels, percent: number) {
  return [h, s, l * percent];
}

export function darken([h, s, l]: HSLChannels, percent: number) {
  return [h, s * percent, l * (2 - percent)];
}

/**
 * Gets the red, green, and blue color channels from an hexadecimal color code.
 * @param {string} hexColorCode Hexadecimal color code.
 *  Leading `#` is optional and the code can either be 3 or 6 characters long.
 * @returns An RGB string of the same color.
 */
export function hexColorToRGB(hexColorCode: string) {
  const channels = getColorChannels(hexColorCode);

  if (!channels) {
    return '';
  }

  return `rgb(${channels.r}, ${channels.g}, ${channels.b})`;
}

/**
 * Get either black or white as a contrasting color to the given color.
 * @param {string} hexColorCode Hexadecimal color code.
 * @returns The white or black color code that contrasts with the given color.
 */
export function getContrastHexColor(hexColorCode: string) {
  const channels = getColorChannels(hexColorCode);
  if (!channels) {
    return '#000000';
  }

  const ratio = channels.r * 0.299 + channels.g * 0.587 + channels.b * 0.114;
  return ratio >= 128 ? '#000000' : '#ffffff';
}

/**
 * Shade a color towards white or black, or blend two colors.
 *
 * Colors can be specified either as RGB with `rgb(...)` or `rgba(...)` strings,
 * or as hex codes with `#` + 3, 4, 6, or 8 characters. The returned format is
 * the one of the stop color if given, or the one of the start color otherwise.
 *
 * **Warning**: alpha channels are also blended (weighted average), and alpha value
 * is 1 by default for both `startColor` and `stopColor`.
 *
 * @param {number} percentage Shading or blending percentage.
 *  - [-1.0; 1.0] for shading a color, with negative values for darker shades.
 *  - [0.0; 1.0] for blending two colors. 0 is the starting color, 1 is the stop color.
 * @param {string} startColor Start color of the shading or blending, or to convert.
 * @param {string} stopColor Blending stop color.
 *   - Omit to shade `startColor`.
 *   - Provide a color value to blend from `startColor` to it.
 * @param {boolean} useLinear Use linear shading / blending instead of logarithmic.
 *  Defaults to `false`.
 * @returns The resulting color.
 *
 * @see https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js) (v4.1)
 */
export function shadeOrBlendColor(percentage: number, startColor: string, stopColor?: string, useLinear: boolean = false) {
  const startColorSyntax = colorStringSyntax(startColor);
  const stopColorSyntax = stopColor && colorStringSyntax(stopColor);

  if (Math.abs(percentage) > 1) {
    throw new RangeError('percentage must be between -1 and 1');
  }

  if (!startColorSyntax || (stopColor && !stopColorSyntax)) {
    throw new TypeError('startColor and stopColor must be a standard hex or rgb color');
  }

  const startColorChannels = getColorChannels(startColor);
  let stopColorChannels = undefined;

  if (stopColor) {
    stopColorChannels = getColorChannels(stopColor);
  } else if (percentage < 0) {
    stopColorChannels = { r: 0, g: 0, b: 0, a: 1 };
  } else {
    stopColorChannels = { r: 255, g: 255, b: 255, a: 1 };
  }

  const absolutePercentage = Math.abs(percentage);
  const inversedPercentage = 1 - absolutePercentage;
  const alphaChannel = startColorChannels.a * inversedPercentage + stopColorChannels.a * absolutePercentage;

  const resultColorChannels = { r: 0, g: 0, b: 0, a: alphaChannel };
  if (useLinear) {
    resultColorChannels.r = Math.round(inversedPercentage * startColorChannels.r + absolutePercentage * stopColorChannels.r);
    resultColorChannels.g = Math.round(inversedPercentage * startColorChannels.g + absolutePercentage * stopColorChannels.g);
    resultColorChannels.b = Math.round(inversedPercentage * startColorChannels.b + absolutePercentage * stopColorChannels.b);
  } else {
    resultColorChannels.r = Math.round(
      (inversedPercentage * startColorChannels.r ** 2 + absolutePercentage * stopColorChannels.r ** 2) ** 0.5
    );
    resultColorChannels.g = Math.round(
      (inversedPercentage * startColorChannels.g ** 2 + absolutePercentage * stopColorChannels.g ** 2) ** 0.5
    );
    resultColorChannels.b = Math.round(
      (inversedPercentage * startColorChannels.b ** 2 + absolutePercentage * stopColorChannels.b ** 2) ** 0.5
    );
  }

  if ((stopColor && stopColorSyntax === 'rgb') || (!stopColor && startColorSyntax === 'rgb')) {
    return `rgba(${resultColorChannels.r},${resultColorChannels.g},${resultColorChannels.b},${
      Math.round(resultColorChannels.a * 1000) / 1000
    })`;
  } else {
    return (
      '#' +
      (
        4294967296 +
        resultColorChannels.r * 16777216 +
        resultColorChannels.g * 65536 +
        resultColorChannels.b * 256 +
        Math.round(resultColorChannels.a * 255)
      )
        .toString(16)
        .slice(1)
    );
  }
}

/**
 * Get a color's channels information.
 * @param {string} color Color to rip.
 *  Format can be `rgb()`, `rgba()`, or hex code with `#` + 3, 4, 6, or 8 characters.
 * @returns The color information as an object with `r`, `g`, `b`, and `a` properties.
 *  Color channel values are in [0; 255].
 *  `a` is in [0.0; 1.0].
 */
export function getColorChannels(color: string) {
  const colorSyntax = colorStringSyntax(color);

  if (!colorSyntax) {
    throw new TypeError('color must use hex or rgb syntax');
  }

  if (colorSyntax === 'rgb') {
    const parsedChannels = color.match(/\((.*)\)/)![1].split(',');
    const [red, green, blue, alpha] = parsedChannels;

    return {
      r: parseInt(red),
      g: parseInt(green),
      b: parseInt(blue),
      a: alpha ? parseFloat(alpha) : 1,
    };
  } else {
    const decimalColor = parseInt(expandHexCode(color).slice(1), 16);
    const hasAlpha = color.length === 9;

    return {
      r: (decimalColor >> (hasAlpha ? 24 : 16)) & 255,
      g: (decimalColor >> (hasAlpha ? 16 : 8)) & 255,
      b: (decimalColor >> (hasAlpha ? 8 : 0)) & 255,
      a: hasAlpha ? Math.round((decimalColor & 255) / 0.255) / 1000 : 1,
    };
  }
}

/**
 * Get the color syntax of a string.
 * Does not verify that the color is valid.
 * @param value Color string to check
 * @returns
 *    - `hex` if the string uses hexadecimal notation;
 *    - `rgb` if it uses rgb() notation;
 *    - `undefined` if it's invalid.
 */
export function colorStringSyntax(value: string) {
  if (/rgba?\(\s?[0-9]{1,3}\s?,\s?[0-9]{1,3}\s?,\s?[0-9]{1,3}\s?(?:,\s?[0-9]+(?:\.[0-9]+)?\s?)?\)/i.test(value)) {
    return 'rgb';
  } else if (/#(?:[0-9a-f]*)/i.test(value) && [4, 5, 7, 9].includes(value.length)) {
    return 'hex';
  }
}

/**
 * Expand a hex color code to 6 or 8 digits depending on
 * whether there is an alpha channel or not.
 * @param color Color code to expand.
 * @returns A 6- or 8-digit hex code.
 */
export function expandHexCode(color: string) {
  if (colorStringSyntax(color) !== 'hex') {
    throw new TypeError('color must be a hex code');
  }

  if (color.length < 7) {
    return '#' + color[1] + color[1] + color[2] + color[2] + color[3] + color[3] + (color.length === 4 ? color[4] + color[4] : '');
  }

  return color;
}
