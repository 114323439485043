import { clsx } from 'clsx';
import { ComponentProps, ElementType } from 'react';

import './Heading.scss';

type HeadingProps = ComponentProps<'h1'> & {
  as?: ElementType;
  level?: 1 | 2 | 3 | 4 | 5;
};

export function Heading({ as, className: additionalClassName, level = 1, ...htmlAttributes }: HeadingProps) {
  const Level = `h${level}` as const;
  const Component = as ?? Level;
  const levelClassName = `Heading--level-${level}`;
  const className = clsx('Heading', levelClassName, additionalClassName);

  return <Component {...htmlAttributes} className={className} />;
}
