import { createContext, ReactNode, useState } from 'react';

import { getColorChannels, hexColorToRGB } from 'src/helpers/colors';

import { createConfiguration, VARIANTS } from './colorConfiguration';
import { ExtranetColorConfiguration } from './colorConfiguration.types';
import './ColorConfigurationProvider.scss';

type PortalContainerRef = HTMLDivElement | null;
type ColorConfigurationContextValue = undefined | { configuration: ExtranetColorConfiguration; containerRef: PortalContainerRef };

type ColorConfigurationProviderProps = {
  configuration: Parameters<typeof createConfiguration>[0];
  children: ReactNode;
};

export const ColorConfigurationContext = createContext<ColorConfigurationContextValue>(undefined);

export function ColorConfigurationProvider(props: ColorConfigurationProviderProps) {
  const [containerRef, setContainerRef] = useState<PortalContainerRef>(null);
  const configuration = createConfiguration(props.configuration);

  return (
    <ColorConfigurationContext.Provider value={{ configuration, containerRef }}>
      <div ref={setContainerRef} className='ColorConfigurationProvider' style={cssStyleFromConfiguration(configuration)}>
        {props.children}
      </div>
    </ColorConfigurationContext.Provider>
  );
}

function cssStyleFromConfiguration(colors: ExtranetColorConfiguration) {
  const style: Record<string, string> = {};

  for (const color of Object.values(colors)) {
    const colorChannels = getColorChannels(color.value);

    style[`${color.cssCustomProperty}-rgb`] = `${colorChannels.r}, ${colorChannels.g}, ${colorChannels.b}`;
    style[color.cssCustomProperty] = hexColorToRGB(color.value);

    for (const variant of VARIANTS) {
      const variantChannels = getColorChannels(color[variant].value);

      style[`${color[variant].cssCustomProperty}-rgb`] = `${variantChannels.r}, ${variantChannels.g}, ${variantChannels.b}`;
      style[color[variant].cssCustomProperty] = hexColorToRGB(color[variant].value);
    }
  }

  return style;
}
