const ThemedBlock = ({ colors, children }) => {
  return (
    <div
      style={{
        minHeight: '100dvh',
        '--user-contrast-color': `${colors.textColor}`,
        '--user-custom-color': `${colors.customColor}`,
        '--user-custom-color-10': `${colors.customColor10}`,
        '--user-custom-color-20': `${colors.customColor20}`,
        '--user-custom-color-40': `${colors.customColor40}`,
        '--user-custom-color-60': `${colors.customColor60}`,
        '--user-custom-color-80': `${colors.customColor80}`,

        '--contrast-color': `${colors.textColor}`,
        '--custom-color': `${colors.customColor}`,
        '--custom-color-10': `${colors.customColor10}`,
        '--custom-color-20': `${colors.customColor20}`,
        '--custom-color-40': `${colors.customColor40}`,
        '--custom-color-60': `${colors.customColor60}`,
        '--custom-color-80': `${colors.customColor80}`,
        '--custom-highlight-color': `${colors.customColor20}`,
      }}
    >
      {children}
    </div>
  );
};

export default ThemedBlock;
