import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from 'src/components/mainComponents/Button';
import { Heading } from 'src/components/mainComponents/Heading';

import './MaintenancePage.scss';

export function MaintenancePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <section className='MaintenancePage'>
      <Heading>{t('MaintenancePage.message')}</Heading>
      <Button onClick={() => navigate(0)}>{t('RootErrorFallback.refresh')}</Button>
    </section>
  );
}
