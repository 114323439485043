import {
  computeHSLtoNormalizedRGB,
  computeNormalizedColorChannels,
  computeNormalizedRGBtoHSL,
  darken,
  lighten,
} from 'src/helpers/colors';

import { BLACK, WHITE } from './constants';

const computeDerivedColors = color => {
  const normalizedColor = computeNormalizedColorChannels(color);
  const [r, g, b] = normalizedColor;
  const hsl = computeNormalizedRGBtoHSL(normalizedColor);
  const ratio = (r * 299 + g * 587 + b * 114) / (1000 / 255);
  const [textColor, colorFn] = ratio >= 128 ? [BLACK, lighten] : [WHITE, darken];

  const range = [0.8, 0.6, 0.4, 0.2];
  const derivedColorSet = range
    .map(coef => colorFn(hsl, coef))
    .map(hsl => computeHSLtoNormalizedRGB(hsl))
    .map(([r, g, b]) => `rgb(${r * 255}, ${g * 255}, ${b * 255})`);

  return [textColor, derivedColorSet];
};

export default computeDerivedColors;
