import { useQuery } from '@apollo/client';
import { createContext, ReactNode } from 'react';

import { useAuth } from 'src/user';

import { AccountInfo } from './accountInfo.types';
import { ACCOUNT_INFO_QUERY } from './queries';

type AccountInfoProviderProps = {
  children: ReactNode;
};

type AccountInfoContextValue = undefined | AccountInfo;

export const AccountInfoContext = createContext<AccountInfoContextValue>(undefined);

export function AccountInfoProvider(props: AccountInfoProviderProps) {
  const { auth } = useAuth();
  const { data, error, loading } = useQuery(ACCOUNT_INFO_QUERY, {
    skip: auth?.userCode === undefined,
    variables: {
      userCode: auth?.userCode,
    },
  });

  if (error) throw new Error('Failed fetching account info: ' + error.message);
  if (loading && !data) return null;

  return <AccountInfoContext.Provider value={data.accountInfos}>{props.children}</AccountInfoContext.Provider>;
}
