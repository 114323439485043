import { createContext, useContext, useState } from 'react';

export const ConnectionLogData = createContext({});

export const ConnectionLogDataProvider = ({ children }) => {
  const [connectionLogState, setconnectionLogState] = useState();

  return <ConnectionLogData.Provider value={{ connectionLogState, setconnectionLogState }}>{children}</ConnectionLogData.Provider>;
};

export const useAddToconnectionLogState = () => {
  const { connectionLogState, setconnectionLogState } = useContext(ConnectionLogData);
  const addToconnectionLogState = props => {
    setconnectionLogState({ ...connectionLogState, ...props });
  };
  return addToconnectionLogState;
};
