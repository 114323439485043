import clsx from 'clsx';

import { Color } from 'src/components/v2/ColorConfiguration';

import { iconNames } from './iconNames';
import iconsSprite from './icons.svg';
import './SvgIcon.scss';

export type SvgIconProps = {
  name: (typeof iconNames)[number];
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  color?: Color;
  width?: number;
  height?: number;
  className?: string;
};

export function SvgIcon(props: SvgIconProps) {
  const size = props.size ?? 'sm';
  const animationName = getAnimationName(props.name);
  const useStandardSize = !props.width && !props.height;
  const classname = clsx(
    'SvgIcon',
    {
      [`SvgIcon--size-${size}`]: useStandardSize,
      [`SvgIcon--animate-${animationName}`]: !!animationName,
      [`SvgIcon--color-${props.color}`]: props.color,
    },
    props.className
  );

  return (
    <svg className={classname} width={props.width ?? props.height} height={props.height ?? props.width}>
      <use href={`${iconsSprite}#${props.name}`} />
    </svg>
  );
}

function getAnimationName(svgName: SvgIconProps['name']) {
  // See end of file "src/components/mainComponents/Illustration/Illustration.jsx"
  // why this convoluted way of handling animations.

  switch (svgName) {
    case 'loading-spinner--dark--half':
    case 'loading-spinner--dark--one-quarter':
    case 'loading-spinner--dark--three-quarters':
    case 'loading-spinner--light--half':
    case 'loading-spinner--light--one-quarter':
    case 'loading-spinner--light--three-quarters':
      return 'full-rotation';
    default:
      return null;
  }
}
