import { gql } from '@apollo/client';

export const SAVE_COLOR_CONFIGURATION = gql`
  mutation SaveColorConfiguration($configuration: ExtranetColorConfigurationInput) {
    updateExtranetColorConfiguration(configuration: $configuration) {
      id
      primary {
        value
        contrast {
          value
        }
        dark {
          value
        }
        darker {
          value
        }
      }
      secondary {
        value
        contrast {
          value
        }
        dark {
          value
        }
        darker {
          value
        }
      }
      alt_1 {
        value
        contrast {
          value
        }
        dark {
          value
        }
        darker {
          value
        }
      }
      alt_2 {
        value
        contrast {
          value
        }
        dark {
          value
        }
        darker {
          value
        }
      }
      alt_3 {
        value
        contrast {
          value
        }
        dark {
          value
        }
        darker {
          value
        }
      }
    }
  }
`;
