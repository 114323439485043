import clsx from 'clsx';
import { type ComponentPropsWithRef, type ForwardedRef, forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { SvgIcon, SvgIconProps } from 'src/components/mainComponents/SvgIcon';
import { Color } from 'src/components/v2/ColorConfiguration';

import './Button.scss';

type NativeButtonProps = {
  as?: 'button';
} & ComponentPropsWithRef<'button'>;

type AnchorProps = {
  as: 'a';
  href?: string;
} & LinkProps;

type VideocallButtonColor = 'videocall-tile' | 'error';

type ButtonProps = (NativeButtonProps | AnchorProps) & {
  size?: 'md' | 'sm' | 'xl' | '2xl';
  variant?: 'solid' | 'text';
  icon?: SvgIconProps['name'];
  color?: Color | VideocallButtonColor;
  rounded?: boolean;
  fullWidth?: boolean;
  padded?: boolean;
};

function ButtonWithRef(props: ButtonProps, ref: ForwardedRef<HTMLAnchorElement | HTMLButtonElement>) {
  const { as, size = 'md', variant = 'solid', color = 'primary', icon, rounded, fullWidth, padded, ...childrenProps } = props;
  const className = clsx(
    'Button',
    `Button--size-${size}`,
    `Button--variant-${variant}`,
    `Button--color-${color}`,
    {
      'Button--full-width': fullWidth && !rounded,
      'Button--text-only': props.children && !icon,
      'Button--icon-only': icon && !props.children,
      'Button--rounded': rounded,
      'Button--padded': padded,
    },
    props.className
  );

  if (as === 'a') {
    const { to, href } = childrenProps as AnchorProps;
    return (
      <Link {...(childrenProps as LinkProps)} to={href ?? to} className={className} ref={ref as ForwardedRef<HTMLAnchorElement>}>
        <Content />
      </Link>
    );
  } else {
    return (
      <button
        {...(childrenProps as ComponentPropsWithRef<'button'>)}
        className={className}
        ref={ref as ForwardedRef<HTMLButtonElement>}
      >
        <Content />
      </button>
    );
  }

  function Content() {
    return (
      <>
        {props.icon && <SvgIcon name={props.icon} size={size} />}
        {props.children}
      </>
    );
  }
}

export const Button = forwardRef(ButtonWithRef);
