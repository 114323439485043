import { ApolloError, useMutation } from '@apollo/client';
import { createContext, ReactNode, useState } from 'react';

import { useAccountInfo } from 'src/components/AccountInfoProvider';
import { keepProperties } from 'src/helpers/utils';

import { ColorAndVariantValuesDictionary } from './colorConfiguration.types';
import { ColorConfigurationProvider } from './ColorConfigurationProvider';
import { SAVE_COLOR_CONFIGURATION } from './queries';

type ColorConfigurationLoaderContextValue =
  | undefined
  | {
      saveConfiguration: (configuration: ColorAndVariantValuesDictionary) => Promise<void>;
      isSavingColorConfiguration: boolean;
      saveColorConfigurationError: ApolloError | undefined;
      saveColorConfigurationData: {
        updateExtranetColorConfiguration: boolean;
      };
    };

type ColorConfigurationLoaderProviderProps = {
  children: ReactNode;
};

export const ColorConfigurationLoaderContext = createContext<ColorConfigurationLoaderContextValue>(undefined);

export function ColorConfigurationLoaderProvider(props: ColorConfigurationLoaderProviderProps) {
  const { extranetColorConfiguration, extranetCustomColor } = useAccountInfo();
  const [savedConfiguration, setSavedConfiguration] = useState(extranetColorConfiguration ?? extranetCustomColor);
  const [
    commitSaveColorConfiguration,
    { loading: isSavingColorConfiguration, error: saveColorConfigurationError, data: saveColorConfigurationData },
  ] = useMutation(SAVE_COLOR_CONFIGURATION);

  return (
    <ColorConfigurationLoaderContext.Provider
      value={{ saveConfiguration, isSavingColorConfiguration, saveColorConfigurationError, saveColorConfigurationData }}
    >
      <ColorConfigurationProvider configuration={savedConfiguration}>{props.children}</ColorConfigurationProvider>
    </ColorConfigurationLoaderContext.Provider>
  );

  /**
   * Save the given color configuration.
   * @param configuration Color configuration to save.
   */
  async function saveConfiguration(configuration: ColorAndVariantValuesDictionary) {
    const cleanConfiguration = keepProperties(configuration, ['value']) as ColorAndVariantValuesDictionary;

    const isConfigurationSaved = await commitSaveColorConfiguration({
      variables: {
        configuration: cleanConfiguration,
      },
    });

    if (isConfigurationSaved) {
      setSavedConfiguration(cleanConfiguration);
    }
  }
}
