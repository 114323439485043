import * as Sentry from '@sentry/react';

import { CONFIG } from 'src/config';
import { getGuestInfo, getUserCode } from 'src/user';

const instrumentation = Sentry;

if (import.meta.env.PROD) {
  initialize();
  addUserToContext();
}

function initialize() {
  instrumentation.init({
    dsn: CONFIG.sentryDsn,
    release: CONFIG.sentryRelease,
    environment: CONFIG.sentryEnv,
  });
}

function addUserToContext() {
  const guestInfo = getGuestInfo();
  const userCode = getUserCode();

  if (guestInfo) {
    instrumentation.setUser({
      ...guestInfo,
      userCode,
      userType: guestInfo.type, // `type` is a reserved property name.
    });
  }
}

export { instrumentation };
