import { useTranslation } from 'react-i18next';

const ERROR_TRANSLATIONS = {
  400: 'Error.message_400',
  401: 'Error.message_401',
  404: 'Error.message_404',
  500: 'Error.message_500',
};

function useErrorMessage(code) {
  const { t } = useTranslation();

  return t(ERROR_TRANSLATIONS[code], {
    defaultValue: t('Error.default_message'),
  });
}

export default useErrorMessage;
