import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import 'moment/dist/locale/de';
import 'moment/dist/locale/es';
import 'moment/dist/locale/fr';

import { CONFIG } from 'src/config';

import deTranslations from './de.json';
import enTranslations from './en.json';
import esTranslations from './es.json';
import frTranslations from './fr.json';
import itTranslations from './it.json';

const LOCALE_KEY = 'i18nextLng';

export const languageCode = Object.freeze({
  english: 'en',
  french: 'fr',
  spanish: 'es',
  german: 'de',
  italian: 'it',
});
const languageCodes = Object.keys(languageCode);

// the translations
const resources = {
  [languageCode.english]: {
    default: enTranslations,
  },
  [languageCode.french]: {
    default: frTranslations,
  },
  [languageCode.spanish]: {
    default: esTranslations,
  },
  [languageCode.german]: {
    default: deTranslations,
  },
  [languageCode.italian]: {
    default: itTranslations,
  },
};

const instance = i18n.createInstance();

instance.on('languageChanged', setMomentLocale);
instance.on('languageChanged', updateHtmlLang);

instance.use(LanguageDetector).init(
  {
    resources,
    languages: languageCodes,
    fallbackLng: languageCode.french,
    ns: ['default'],
    defaultNS: 'default',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    debug: CONFIG.env === 'dev',
  },
  err => {
    if (err) return console.error('MomentJS initialization failed', err);
  }
);

export default instance;

function updateHtmlLang(locale) {
  document.documentElement.lang = locale;
}

export function setMomentLocale(locale) {
  moment.locale(locale);
}

export function setLocale(locale) {
  instance.changeLanguage(locale);
}

export function getLocale() {
  return localStorage.getItem(`${LOCALE_KEY}`) ?? undefined;
}

export function clearLocale() {
  localStorage.removeItem(`${LOCALE_KEY}`);
}
