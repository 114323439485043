import { ApolloProvider } from '@apollo/client';
import { useReducer, useState } from 'react';

import { MaintenancePage } from 'src/components/MaintenancePage';
import { createApolloClient } from 'src/store/apolloClient';

const MAINTENANCE_STATUS_CODE = 503;

export function AppApolloProvider({ children }) {
  const [isMaintenance, triggerMaintenance] = useReducer(() => true, false);
  const [apolloClient] = useState(
    createApolloClient({
      onNetworkError: networkError => {
        if (networkError.statusCode === MAINTENANCE_STATUS_CODE) triggerMaintenance();
      },
    })
  );
  return <ApolloProvider client={apolloClient}>{isMaintenance ? <MaintenancePage /> : children}</ApolloProvider>;
}
