import { ErrorBoundary, ErrorBoundaryProps, Scope } from '@sentry/react';
import { ReactNode } from 'react';

type InstrumentationErrorBoundaryProps = {
  boundaryId: string;
  fallback: ErrorBoundaryProps['fallback'];
  children: ReactNode | ReactNode[];
  beforeCapture?: () => void;
  resetKey?: string;
};

export function InstrumentationErrorBoundary(props: InstrumentationErrorBoundaryProps) {
  return (
    <ErrorBoundary key={props.resetKey} beforeCapture={augmentCaptureInfo} fallback={props.fallback}>
      {props.children}
    </ErrorBoundary>
  );

  function augmentCaptureInfo(scope: Scope) {
    scope.setTag('errorBoundaryId', props.boundaryId);
    props.beforeCapture?.();
  }
}
