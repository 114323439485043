import { getContrastHexColor, shadeOrBlendColor } from 'src/helpers/colors';
import { DeepPartial } from 'src/helpers/helpers.types';

import {
  Color,
  ColorAndVariantValuesDictionary,
  ColorTranslationKey,
  ColorValuesDictionary,
  ColorVariant,
  ExtranetColorConfiguration,
  UpdateColorOptions,
} from './colorConfiguration.types';

/**
 * Base color name.
 *
 * Any update in this list must update PaletteColorPropType in src/propTypes.js.
 */
export const COLOR = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  ALT_1: 'alt_1',
  ALT_2: 'alt_2',
  ALT_3: 'alt_3',
} as const;

/**
 * Available colors.
 */
export const COLORS = Object.values(COLOR);

/**
 * Variant name.
 */
export const VARIANT = {
  CONTRAST: 'contrast',
  DARK: 'dark',
  DARKER: 'darker',
} as const;

/**
 * Available variants.
 */
export const VARIANTS = Object.values(VARIANT);

const DEFAULT_COLOR_VALUES: ColorValuesDictionary = {
  primary: {
    value: '#2121e8',
  },
  secondary: {
    value: '#5625e9',
  },
  alt_1: {
    value: '#8a28eb',
  },
  alt_2: {
    value: '#bb27ec',
  },
  alt_3: {
    value: '#ed2ced',
  },
};

/**
 * Dictionary of all color translation keys.
 *
 * This is required to avoid dynamic translation keys because we can't track
 * unused translations if they are dynamicly built.
 *
 * This object is still type-safe.
 */
const colorTranslation: { [key in Color]: { base: ColorTranslationKey } & { [key in ColorVariant]: ColorTranslationKey } } = {
  primary: {
    base: 'ColorConfiguration.color_primary',
    contrast: 'ColorConfiguration.color_primary_contrast',
    dark: 'ColorConfiguration.color_primary_dark',
    darker: 'ColorConfiguration.color_primary_darker',
  },
  secondary: {
    base: 'ColorConfiguration.color_secondary',
    contrast: 'ColorConfiguration.color_secondary_contrast',
    dark: 'ColorConfiguration.color_secondary_dark',
    darker: 'ColorConfiguration.color_secondary_darker',
  },
  alt_1: {
    base: 'ColorConfiguration.color_alt_1',
    contrast: 'ColorConfiguration.color_alt_1_contrast',
    dark: 'ColorConfiguration.color_alt_1_dark',
    darker: 'ColorConfiguration.color_alt_1_darker',
  },
  alt_2: {
    base: 'ColorConfiguration.color_alt_2',
    contrast: 'ColorConfiguration.color_alt_2_contrast',
    dark: 'ColorConfiguration.color_alt_2_dark',
    darker: 'ColorConfiguration.color_alt_2_darker',
  },
  alt_3: {
    base: 'ColorConfiguration.color_alt_3',
    contrast: 'ColorConfiguration.color_alt_3_contrast',
    dark: 'ColorConfiguration.color_alt_3_dark',
    darker: 'ColorConfiguration.color_alt_3_darker',
  },
};

/**
 * Compute the value of a variant of the given color.
 * @param variant Variant to compute (one of {@link VARIANT}.)
 * @param colorValue Value to compute from.
 * @returns Computed value for the requested variant of the given color.
 *  Returns `colorValue` if `variant` is invalid.
 */
export function computeVariantValue(variant: ColorVariant, colorValue: string) {
  switch (variant) {
    case 'contrast':
      return getContrastHexColor(colorValue);
    case 'dark':
      return shadeOrBlendColor(-0.2, colorValue);
    case 'darker':
      return shadeOrBlendColor(-0.4, colorValue);
  }
}

/**
 * Create a color configuration from the given value or object.
 * Uses default values if none provided.
 *
 * If a single color value is provided and equal to `#ffffff`,
 * it will be overridden with the default primary color instead.
 *
 * @param colorValues Color values to use in the configuration.
 */
export function createConfiguration(colorValues: string | DeepPartial<ColorAndVariantValuesDictionary> | undefined) {
  const configuration: ExtranetColorConfiguration = {} as ExtranetColorConfiguration;
  const inputConfiguration =
    !colorValues || typeof colorValues === 'string' ? createConfigurationFromSingleColor(colorValues) : colorValues;

  for (const color of COLORS) {
    if (!configuration[color]) {
      configuration[color] = {} as unknown as ExtranetColorConfiguration[Color];
    }

    configuration[color].value = inputConfiguration[color]?.value ?? DEFAULT_COLOR_VALUES[color].value;
    configuration[color].name = color;
    configuration[color].displayNameTranslationKey = colorTranslation[color].base;
    configuration[color].cssCustomProperty = `--colors-${color}`;

    for (const variant of VARIANTS) {
      if (!configuration[color][variant]) {
        configuration[color][variant] = {} as unknown as ExtranetColorConfiguration[Color];
      }

      configuration[color][variant].value =
        inputConfiguration[color]?.[variant]?.value ?? computeVariantValue(variant, configuration[color].value);
      configuration[color][variant].name = `${color}${variant}`;
      configuration[color][variant].displayNameTranslationKey = colorTranslation[color][variant];
      configuration[color][variant].cssCustomProperty = `--colors-${color}-${variant}`;
    }
  }

  return configuration;
}

/**
 * Update a color in the given configuration object.
 * @param configuration Whole color configuration to update.
 * @param colorToUpdate Name of the color to update in the configuration.
 * @param options Update options.
 */
export function updateColorInConfiguration(
  configuration: ColorAndVariantValuesDictionary,
  colorToUpdate: Color,
  options: UpdateColorOptions
) {
  if (options.value) {
    configuration[colorToUpdate].value = options.value;

    for (const variant of options.autoVariants ?? []) {
      configuration[colorToUpdate][variant].value = computeVariantValue(variant, options.value);
    }
  }

  for (const variant of VARIANTS) {
    if (options[variant]) {
      configuration[colorToUpdate][variant].value = options[variant];
    }
  }
}

function createConfigurationFromSingleColor(colorValue: string | undefined): ColorAndVariantValuesDictionary {
  // Don't allow white because it makes the UI hard to use.
  const sanitizedColorValue = !colorValue || /^#f*$/i.test(colorValue) ? DEFAULT_COLOR_VALUES['primary'].value : colorValue;

  const configuration = {} as ColorAndVariantValuesDictionary;

  for (const color of COLORS) {
    configuration[color] = {
      value: sanitizedColorValue,
    } as unknown as ColorAndVariantValuesDictionary[Color];

    for (const variant of VARIANTS) {
      configuration[color][variant] = {
        value: computeVariantValue(variant, sanitizedColorValue),
      };
    }
  }

  return configuration;
}
