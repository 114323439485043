import { useEffect } from 'react';

import Routes from 'src/components/routes/Routes';
import { RootErrorBoundary } from 'src/components/v2/RootErrorBoundary';
import { Providers } from 'src/Providers';
import { getUserCode } from 'src/user';

export function App() {
  useEffect(() => {
    if (getUserCode() === 'www' && window.location.pathname !== '/home') {
      window.location.pathname = '/home';
    }
  }, []);

  return (
    <Providers>
      <RootErrorBoundary>
        <Routes />
      </RootErrorBoundary>
    </Providers>
  );
}
