import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from 'src/components/mainComponents/Button';
import { Heading } from 'src/components/mainComponents/Heading';
import useErrorMessage from 'src/hooks/useErrorMessage';

import './RootErrorBoundary.scss';

type RootErrorFallbackProps = {
  error: Error;
};

export function RootErrorFallback(props: RootErrorFallbackProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const errorMessage = useErrorMessage(props.error.message);

  return (
    <div className='RootErrorBoundary' role='alert'>
      <Heading>{t('RootErrorFallback.title')}</Heading>
      <p className='RootErrorBoundary__error-text'>{errorMessage}</p>
      <div className='RootErrorBoundary__actions'>
        <Button variant='text' onClick={() => navigate(0)}>
          {t('RootErrorFallback.refresh')}
        </Button>
        <Button as='a' to='/' icon='home' size='xl'>
          {t('RootErrorFallback.go_home')}
        </Button>
      </div>
    </div>
  );
}
