import { useLocation } from 'react-router-dom';

import { ChildrenOnlyProps } from 'src/components/types';
import { RootErrorFallback } from 'src/components/v2/RootErrorBoundary/RootErrorFallback';
import { InstrumentationErrorBoundary } from 'src/instrumentation/InstrumentationErrorBoundary';

export function RootErrorBoundary(props: ChildrenOnlyProps) {
  const location = useLocation();

  return (
    <InstrumentationErrorBoundary
      resetKey={location.pathname}
      fallback={({ error }) => <RootErrorFallback error={error} />}
      boundaryId='root'
    >
      {props.children}
    </InstrumentationErrorBoundary>
  );
}
