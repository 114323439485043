import { useContext } from 'react';

import { AccountInfoContext } from '.';

export function useAccountInfo() {
  const context = useContext(AccountInfoContext);

  if (!context) {
    throw new Error('useAccountInfo must be used withing AccountInfoProvider');
  }

  return context;
}
